import React from 'react'

const SideNavigation = props => {
    return (
        <aside className=" py-6 px-2 lg:py-2 lg:pl-0 lg:pr-12 lg:col-span-3">
            <nav className="space-y-1">{props.children}</nav>
        </aside>
    )
}

export default SideNavigation
