import React, { useState, useEffect, useContext } from 'react'

import avatarPlaceholder from '../../../assets/img/avatarPlaceholder.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faUser } from '@fortawesome/free-solid-svg-icons'

import { useHttpClient } from '../../hooks/httpHook'
import { AuthContext } from '../../context/authContext'

const GuestSelect = props => {
    const auth = useContext(AuthContext)

    const [dropdownVisible, setDropdownVisible] = useState(false)
    const { isLoading, sendRequest } = useHttpClient()
    const [loadedGuests, setLoadedGuests] = useState()
    const [selectedGuest, setSelectedGuest] = useState()

    useEffect(() => {
        const fetchGuests = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/guest`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )
                setLoadedGuests(resData.guests)
            } catch (err) {}
        }
        fetchGuests()
    }, [sendRequest, auth.token])

    const handleDropdownVisibilityChange = () => {
        setDropdownVisible(prevMode => !prevMode)
    }

    const handleUserSelectionChange = guest => {
        setDropdownVisible(false)
        setSelectedGuest(guest)
        props.handleGuestSelection(guest)
    }

    return (
        <div className="mb-8">
            <label
                id="listbox-label"
                className="block text-sm font-medium text-gray-700"
            >
                Buchen für
            </label>
            <div className="relative mt-1">
                <button
                    onClick={handleDropdownVisibilityChange}
                    type="button"
                    className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    {!selectedGuest && (
                        <React.Fragment>
                            <span className="flex items-center">
                                <img
                                    src={avatarPlaceholder}
                                    alt=""
                                    className="flex-shrink-0 w-6 h-6 rounded-full"
                                />
                                <span className="block ml-3 font-semibold truncate text-normal">
                                    Bitte einen Gast auswählen
                                </span>
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                <FontAwesomeIcon icon={faArrowDown} />
                            </span>
                        </React.Fragment>
                    )}
                    {selectedGuest && (
                        <React.Fragment>
                            <span className="flex items-center">
                                <img
                                    src={avatarPlaceholder}
                                    alt=""
                                    className="flex-shrink-0 w-6 h-6 rounded-full"
                                />
                                <span className="block ml-3 font-semibold truncate text-normal">
                                    {selectedGuest.name}
                                </span>
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                <FontAwesomeIcon icon={faArrowDown} />
                            </span>
                        </React.Fragment>
                    )}
                </button>

                {!isLoading && loadedGuests && (
                    <ul
                        className={`${
                            dropdownVisible ? 'absolute' : 'hidden'
                        } z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
                    >
                        {loadedGuests.map(guest => (
                            <li
                                key={guest.id}
                                className="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9 hover:bg-gray-50 hover:cursor-pointer"
                                onClick={() => handleUserSelectionChange(guest)}
                            >
                                <div className="flex items-center">
                                    <img
                                        src={avatarPlaceholder}
                                        alt=""
                                        className="flex-shrink-0 w-6 h-6 rounded-full"
                                    />

                                    <span className="block ml-3 font-semibold truncate text-normal">
                                        {guest.name}
                                    </span>
                                </div>

                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-900">
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default GuestSelect
