import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

import { VALIDATOR_MIN } from '../../shared/util/validators'

const UserTransaction = props => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler, setFormData] = useForm(
        {
            amount: {
                value: 0,
                isValid: false,
            },
        },
        false
    )

    const depositCreditSubmitHandler = async event => {
        event.preventDefault()
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/transactions/deposit/${props.userId}`,
                'POST',
                JSON.stringify({
                    amount: formState.inputs.amount.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success('Guthaben wurde erfolgreich hinzugefügt.')
            navigate('/users')
        } catch (e) {}
    }

    return (
        <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && (
                <div className="py-4 grid grid-cols-3">
                    <p className="block col-span-1 font-semibold">
                        Guthaben hinzufügen
                    </p>
                    <form
                        className="space-y-2 col-span-2"
                        onSubmit={depositCreditSubmitHandler}
                    >
                        <Input
                            element="input"
                            id="amount"
                            min={0}
                            type="number"
                            label="Betrag"
                            errorText="Der Betrag muss über 0€ liegen."
                            validators={[VALIDATOR_MIN(0)]}
                            onInput={inputHandler}
                        />

                        <button
                            disabled={!formState.isValid}
                            type="submit"
                            className="
                                        disabled:opacity-50
                                        disabled:cursor-not-allowed
                                    w-full
                                    flex
                                    justify-center
                                    py-2
                                    px-4
                                    border border-transparent
                                    rounded-md
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    text-white
                                    bg-indigo-600
                                    hover:bg-indigo-700
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-indigo-500
                                "
                        >
                            Guthaben hinzufügen
                        </button>
                    </form>
                </div>
            )}
        </React.Fragment>
    )
}

export default UserTransaction
