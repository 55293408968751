import React, { useContext, useEffect, useState } from 'react'

import Spinner from '../../shared/components/UIElements/Spinner'
import TextHeader from '../../shared/components/UIElements/TextHeader'

import { AuthContext } from '../../shared/context/authContext'
import { useHttpClient } from '../../shared/hooks/httpHook'
import BookForOther from '../components/BookForOther'
import Booking from '../components/Booking'
import TabGroup from '../components/TabGroup'

const Terminal = () => {
    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [loadedContext, setLoadedContext] = useState()

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/${auth.userId}`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )

                setLoadedContext(resData.user)
            } catch (err) {}
        }
        fetchUser()
    }, [sendRequest, auth.token, auth.userId])

    return (
        <div>
            {isLoading && <Spinner />}
            {!isLoading && loadedContext && (
                <div>
                    {auth.role !== 'terminal' && (
                        <TextHeader
                            text="Willkommen"
                            name={loadedContext.username}
                        />
                    )}
                    {auth.role !== 'owner' && <Booking />}
                    {auth.role === 'owner' && (
                        <TabGroup
                            tabs={[
                                {
                                    key: 'normal',
                                    name: 'Standard',
                                    children: <Booking />,
                                },
                                {
                                    key: 'bookForOther',
                                    name: 'Für andere Buchen',
                                    children: <BookForOther />,
                                },
                            ]}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default Terminal
