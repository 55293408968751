import React from 'react'

import TextHeader from '../../shared/components/UIElements/TextHeader'
import BalanceOverview from '../components/BalanceOverview'

const GeneralOverview = () => {
    return (
        <React.Fragment>
            <TextHeader text="Guthabenübersicht" />
            <BalanceOverview />
        </React.Fragment>
    )
}

export default GeneralOverview
