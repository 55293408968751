import React, { useContext, useEffect, useState } from 'react'

import Spinner from '../../shared/components/UIElements/Spinner'
import EmptyState from '../../shared/components/UIElements/EmptyState'

import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

const PersonalBookings = () => {
    const auth = useContext(AuthContext)
    const { isLoading, sendRequest } = useHttpClient()
    const [loadedBookings, setLoadedBookings] = useState()

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/transactions`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )

                setLoadedBookings(resData.bookings)
            } catch (err) {}
        }
        fetchBookings()
    }, [sendRequest, auth.token, auth.userId])

    return (
        <div className="mt-8">
            <h3 className="text-xl font-medium leading-6 text-gray-900">
                Letzen 15 Buchungen
            </h3>

            <div className="flex flex-col mt-5">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full border-separate divide-y divide-gray-200 table-fixed">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            #
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Datum
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Bechreibung
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Betrag
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Typ
                                        </th>
                                    </tr>
                                </thead>
                                {!isLoading && loadedBookings && (
                                    <tbody className="w-full bg-white divide-y divide-gray-200">
                                        {loadedBookings.map(
                                            (booking, index) => (
                                                <tr
                                                    className="font-normal hover:bg-gray-50"
                                                    key={booking.id}
                                                >
                                                    <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                        {index + 1}
                                                    </td>
                                                    <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                        {new Date(
                                                            booking.created_at
                                                        ).toLocaleString(
                                                            'de-DE',
                                                            {
                                                                timeZone: 'ECT',
                                                            }
                                                        )}
                                                    </td>
                                                    <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                        {booking.description}
                                                    </td>
                                                    <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                        <span
                                                            className={`px-3 py-1 inline-flex text-sm leading-5 font-bold rounded-full ${
                                                                booking.type ===
                                                                'payment'
                                                                    ? 'bg-red-100 text-red-800'
                                                                    : 'bg-green-100 text-green-800'
                                                            }`}
                                                        >
                                                            {booking.type ===
                                                                'payment' && (
                                                                <span>-</span>
                                                            )}
                                                            {booking.type ===
                                                                'reposit' && (
                                                                <span>+</span>
                                                            )}
                                                            {parseFloat(
                                                                booking.amount
                                                            ).toFixed(2)}
                                                            &#8364;
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                        {booking.type ===
                                                            'payment' &&
                                                            'Zahlung'}
                                                        {booking.type ===
                                                            'deposit' &&
                                                            'Einzahlung'}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                )}
                            </table>

                            {isLoading && <Spinner />}
                            {!isLoading && !loadedBookings && (
                                <EmptyState title="Keine Buchungen gefunden" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalBookings
