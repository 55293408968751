import React from 'react'

import { Link } from 'react-router-dom'

import avatarPlaceholder from '../../assets/img/avatarPlaceholder.png'

const UserTableItem = props => {
    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                        <img
                            className="h-10 w-10 rounded-full"
                            src={avatarPlaceholder}
                            alt=""
                        />
                    </div>
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {props.user.username}
                        </div>
                        <div className="text-sm text-gray-500">
                            {props.user.email}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <span>{parseFloat(props.user.balance).toFixed(2)} &#8364;</span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <span>{props.user.role}</span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span
                    className={`px-3 inline-flex text-xs leading-5 font-bold rounded-full ${
                        props.user.active
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                    }`}
                >
                    {props.user.active ? 'Aktiviert' : 'Deaktiviert'}
                </span>
            </td>

            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <Link to={`/users/${props.user.id}`}>
                    <span className="text-bold text-sm text-indigo-600 hover:text-indigo-900">
                        Bearbeiten
                    </span>
                </Link>
            </td>
        </tr>
    )
}

export default UserTableItem
