import React, { useState, useEffect, useContext } from 'react'

import DrinkTableItem from './DrinkTableItem'
import Spinner from '../../shared/components/UIElements/Spinner'
import EmptyState from '../../shared/components/UIElements/EmptyState'

import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

const DrinkTable = () => {
    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [loadedDrinks, setLoadedDrinks] = useState()

    useEffect(() => {
        const fetchDrinks = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/drinks`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )
                setLoadedDrinks(resData.drinks)
            } catch (err) {}
        }
        fetchDrinks()
    }, [sendRequest])

    const drinkDeleteHandler = deletedDrinkId => {
        setLoadedDrinks(prevDrinks =>
            prevDrinks.filter(drink => drink.id !== deletedDrinkId)
        )
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200 table-fixed border-separate">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Preis
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Gastpreis
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Icon
                                    </th>
                                    <th
                                        scope="col"
                                        className="relative px-6 py-3"
                                    >
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>

                            {!isLoading && loadedDrinks && (
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {loadedDrinks.map(drink => (
                                        <DrinkTableItem
                                            key={drink.id}
                                            drink={drink}
                                            onDelete={drinkDeleteHandler}
                                        />
                                    ))}
                                </tbody>
                            )}
                        </table>
                        {isLoading && <Spinner />}
                        {!isLoading &&
                            loadedDrinks &&
                            loadedDrinks.length === 0 && (
                                <EmptyState
                                    title="Keine Getränke!"
                                    description="Erstelle jetzt das erste Getränk."
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DrinkTable
