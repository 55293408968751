import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'

import Auth from './user/pages/Auth'
import Terminal from './drinkManagment/pages/Terminal'

import Dashboard from './adminDashboard/pages/Dashboard'

import AccountManagment from './account/pages/AccountManagment'
import AccountSettings from './account/components/AccountSettings'

import PersonalOverview from './drinkManagment/pages/PersonalOverview'
import GeneralOverview from './drinkManagment/pages/GeneralOverview'

import AccountEnablement from './account/pages/AccountEnablement'

import UserManagment from './adminDashboard/pages/UserManagment'
import EditUser from './adminDashboard/components/EditUser'
import CreateUser from './adminDashboard/components/CreateUser'
import UserTable from './adminDashboard/components/UserTable'

import DrinkManagment from './adminDashboard/pages/DrinkManagment'
import DrinkTable from './adminDashboard/components/DrinkTable'
import CreateDrink from './adminDashboard/components/CreateDrink'
import EditDrink from './adminDashboard/components/EditDrink'

import MainNavigation from './shared/components/Navigation/MainNavigation'
import { AuthContext } from './shared/context/authContext'
import { useAuth } from './shared/hooks/authHook'
import GuestTerminal from './drinkManagment/pages/GuestTerminal'
import Redirect from './technical/pages/Redirect'
import UserPincode from './adminDashboard/pages/UserPincode'
import UserPassword from './adminDashboard/pages/UserPassword'

const App = () => {
    const { token, login, logout, userId, role } = useAuth()

    let routes

    if (token) {
        routes = (
            <Routes>
                <Route path="/" element={<Terminal />} exact />
                <Route path="/bookings" element={<PersonalOverview />} />
                {['owner', 'admin', 'terminal'].includes(role) && (
                    <Route path="/guests" element={<GuestTerminal />} />
                )}
                <Route path="/overview" element={<GeneralOverview />} />
                <Route path="account" element={<AccountManagment />}>
                    <Route index element={<AccountSettings />} />
                </Route>

                {['owner', 'admin'].includes(role) && (
                    <React.Fragment>
                        <Route path="admin" element={<Dashboard />} />
                        <Route path="users" element={<UserManagment />}>
                            <Route index element={<UserTable />} />
                            <Route path="create" element={<CreateUser />} />
                            <Route path=":userId" element={<EditUser />} />
                        </Route>
                        <Route path="drinks" element={<DrinkManagment />}>
                            <Route index element={<DrinkTable />} />
                            <Route path="create" element={<CreateDrink />} />
                            <Route path=":drinkId" element={<EditDrink />} />
                        </Route>
                        {['owner'].includes(role) && (
                            <Route
                                path="changepincode"
                                element={<UserPincode />}
                            />
                        )}

                        {['owner'].includes(role) && (
                            <Route
                                path="changepassword"
                                element={<UserPassword />}
                            />
                        )}
                    </React.Fragment>
                )}

                <Route path="/activate" element={<AccountEnablement />} />
                <Route path="/redirect" element={<Redirect />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        )
    } else {
        routes = (
            <Routes>
                <Route path="/auth" element={<Auth />} exact />
                <Route path="*" element={<Navigate to="/auth" />} />
            </Routes>
        )
    }

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                role: role,
                login: login,
                logout: logout,
            }}
        >
            <Router>
                {token && <MainNavigation />}
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <main className="min-h-screen bg-gray-100">
                    {token && (
                        <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
                            {routes}
                        </div>
                    )}
                    {!token && routes}
                </main>
            </Router>
        </AuthContext.Provider>
    )
}

export default App
