import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { AuthContext } from '../../context/authContext'

const NavLinks = () => {
    const auth = useContext(AuthContext)

    return (
        <div className="hidden md:ml-6 md:flex md:space-x-8">
            <NavLink
                to="/"
                className={({ isActive }) =>
                    `  
                        text-gray-900
                        inline-flex
                        items-center
                        px-1
                        pt-1
                        border-b-2
                        text-sm
                        font-medium ${
                            isActive
                                ? 'border-indigo-500 hover:border-indigo-500'
                                : 'border-transparent  hover:border-gray-300 hover:text-gray-700'
                        }`
                }
            >
                Terminal
            </NavLink>
            {!['terminal'].includes(auth.role) && (
                <NavLink
                    to="/bookings"
                    className={({ isActive }) =>
                        `  
                        text-gray-900
                        inline-flex
                        items-center
                        px-1
                        pt-1
                        border-b-2
                        text-sm
                        font-medium ${
                            isActive
                                ? 'border-indigo-500 hover:border-indigo-500'
                                : 'border-transparent  hover:border-gray-300 hover:text-gray-700'
                        }`
                    }
                >
                    Buchungen
                </NavLink>
            )}
            {['terminal'].includes(auth.role) && (
                <NavLink
                    to="/overview"
                    className={({ isActive }) =>
                        `  
                        text-gray-900
                        inline-flex
                        items-center
                        px-1
                        pt-1
                        border-b-2
                        text-sm
                        font-medium ${
                            isActive
                                ? 'border-indigo-500 hover:border-indigo-500'
                                : 'border-transparent  hover:border-gray-300 hover:text-gray-700'
                        }`
                    }
                >
                    Übersicht
                </NavLink>
            )}

            {['owner', 'admin', 'terminal'].includes(auth.role) && (
                <NavLink
                    to="/guests"
                    className={({ isActive }) =>
                        `  
                        text-gray-900
                        inline-flex
                        items-center
                        px-1
                        pt-1
                        border-b-2
                        text-sm
                        font-medium ${
                            isActive
                                ? 'border-indigo-500 hover:border-indigo-500'
                                : 'border-transparent  hover:border-gray-300 hover:text-gray-700'
                        }`
                    }
                >
                    Gäste
                </NavLink>
            )}

            {['owner', 'admin', 'terminal'].includes(auth.role) && (
                <NavLink
                    to="/activate"
                    className={({ isActive }) =>
                        `  
                        text-gray-900
                        inline-flex
                        items-center
                        px-1
                        pt-1
                        border-b-2
                        text-sm
                        font-medium ${
                            isActive
                                ? 'border-indigo-500 hover:border-indigo-500'
                                : 'border-transparent  hover:border-gray-300 hover:text-gray-700'
                        }`
                    }
                >
                    Account aktivieren
                </NavLink>
            )}
            {['owner', 'admin'].includes(auth.role) && (
                <NavLink
                    to="admin"
                    className={({ isActive }) =>
                        `  
                    text-gray-900
                    inline-flex
                    items-center
                    px-1
                    pt-1
                    border-b-2
                    text-sm
                    font-medium ${
                        isActive
                            ? 'border-indigo-500 hover:border-indigo-500'
                            : 'border-transparent  hover:border-gray-300 hover:text-gray-700'
                    }`
                    }
                >
                    Admin Dashboard
                </NavLink>
            )}
        </div>
    )
}

export default NavLinks
