import React, { useState, useContext } from 'react'

import { Link } from 'react-router-dom'

import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'

import { AuthContext } from '../../shared/context/authContext'
import { useHttpClient } from '../../shared/hooks/httpHook'
import Modal from '../../shared/components/UIElements/Modal'

import { importAll } from '../../utils/Methods'

const images = importAll(
    require.context('../../assets/img/products', false, /\.(png|jpe?g|svg)$/)
)

const DrinkTableItem = props => {
    const { isLoading, sendRequest } = useHttpClient()

    const auth = useContext(AuthContext)

    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const showDeleteWarningHandler = () => setShowConfirmModal(true)

    const cancelDeleteHandler = () => setShowConfirmModal(false)

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false)
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/drinks/${props.drink.id}`,
                'DELETE',
                null,
                { Authorization: `Bearer ${auth.token}` }
            )
            toast.warn('Getränk wurde gelöscht')
            props.onDelete(props.drink.id)
        } catch (err) {}
    }

    return (
        <React.Fragment>
            <Modal
                show={showConfirmModal}
                onCancel={cancelDeleteHandler}
                title="Getränk löschen?"
                onClick={confirmDeleteHandler}
                buttonText="Löschen"
            >
                <p>
                    Soll das Getränk ({' '}
                    <span className="font-bold">{props.drink.name}</span> )
                    wirklick gelöscht werden?
                </p>
            </Modal>
            <tr className="font-semibold hover:bg-gray-50">
                <td className="px-6 py-4 text-gray-900 text-md">
                    {props.drink.name}
                </td>
                <td className="px-6 py-4 text-gray-900 text-md">
                    {props.drink.price}
                    &#8364;
                </td>
                <td className="px-6 py-4 text-gray-900 text-md">
                    {props.drink.guestprice}
                    &#8364;
                </td>
                <td className="px-6 py-4 text-gray-900 text-md">
                    <img
                        className="h-8"
                        src={images[props.drink.icon]}
                        alt="Icon"
                    />
                </td>
                <td className="px-6 py-4 text-right text-normal">
                    <Link
                        to={`/drinks/${props.drink.id}`}
                        className="mr-3 text-gray-900"
                    >
                        <FontAwesomeIcon icon={faEdit} />
                        <span className="sr-only">Edit</span>
                    </Link>
                    <button
                        className="text-red-600"
                        onClick={showDeleteWarningHandler}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <span className="sr-only">Delete</span>
                    </button>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default DrinkTableItem
