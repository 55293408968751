import React from 'react'
import { NavLink } from 'react-router-dom'

const SideEntry = props => {
    return (
        <NavLink
            to={props.path}
            className={({ isActive }) =>
                `hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-md font-medium w-full ${
                    isActive && 'text-gray-900'
                }`
            }
        >
            {props.children}
            <span className={`truncate ${props.children && 'ml-3'}`}>
                {props.name}
            </span>
        </NavLink>
    )
}

export default SideEntry
