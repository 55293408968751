import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'

import { AuthContext } from '../../shared/context/authContext'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { useForm } from '../../shared/hooks/formHook'

import { VALIDATOR_MINLENGTH } from '../../shared/util/validators'

const AddGuest = () => {
    const navigate = useNavigate()
    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler, setFormData] = useForm(
        {
            name: {
                value: '',
                isValid: false,
            },
        },
        false
    )

    const addGuestSubmitHandler = async event => {
        event.preventDefault()
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/guest`,
                'POST',
                JSON.stringify({
                    name: formState.inputs.name.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success('Der Gast wurde erfolgreich erstellt.')
            navigate('/redirect?uri=/guests')
        } catch (e) {}
    }

    return (
        <div className="relative px-4 py-4 bg-white shadow-xl">
            <h3 className="text-lg font-semibold">Gast hinzufügen:</h3>
            <p className="text-gray-500 text-normal">
                Hier kanst du einen Gast hinzufügen, um anschlißend den Verzehr
                zu buchhen!
            </p>
            {isLoading && <Spinner />}
            {!isLoading && (
                <form
                    className="flex items-start w-full mt-4"
                    onSubmit={addGuestSubmitHandler}
                >
                    <Input
                        label="Name"
                        type="text"
                        id="name"
                        element="input"
                        className="w-full"
                        errorText="Name muss mindestens 2 Zeichen haben!"
                        onInput={inputHandler}
                        validators={[VALIDATOR_MINLENGTH(2)]}
                    />
                    <button
                        type="submit"
                        disabled={!formState.isValid}
                        className="inline-flex items-center justify-center w-full h-10 px-6 py-3 mt-6 ml-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                    >
                        Hinzufügen
                    </button>
                </form>
            )}
        </div>
    )
}

export default AddGuest
