import React, { useReducer, useEffect } from 'react'

import { validate } from '../../util/validators'

const inputReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators),
            }
        case 'TOUCH': {
            return {
                ...state,
                isTouched: true,
            }
        }
        default:
            return state
    }
}

const Input = props => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initalValue || '',
        isTouched: false,
        isValid: props.initalValid || false,
    })

    const { id, onInput } = props
    const { value, isValid } = inputState

    useEffect(() => {
        onInput(id, value, isValid)
    }, [id, value, isValid, onInput])

    const changeHandler = event => {
        dispatch({
            type: 'CHANGE',
            val: event.target.value,
            validators: props.validators,
        })
    }

    const touchHandler = () => {
        dispatch({
            type: 'TOUCH',
        })
    }

    const element =
        props.element === 'input' ? (
            <input
                className={`
            appearance-none
            block
            w-full
            px-3
            py-2
            border border-gray-300
            rounded-md
            shadow-sm
            placeholder-gray-400
            focus:outline-none
            focus:ring-indigo-500
            focus:border-indigo-500   
            sm:text-sm
            
            ${!inputState.isValid && inputState.isTouched && 'border-red-600'}
            `}
                id={props.id}
                type={props.type}
                placeholder={props.placeholder}
                onChange={changeHandler}
                onBlur={touchHandler}
                value={inputState.value}
                autoComplete={props.autoComplete ?? 'on'}
            />
        ) : (
            <textarea
                id={props.id}
                rows={props.rows || 3}
                onChange={changeHandler}
                onBlur={touchHandler}
                value={inputState.value}
            />
        )

    return (
        <div className={`space-y-1 ${props.className}`}>
            <label
                className="block text-sm font-medium text-gray-700"
                htmlFor={props.id}
            >
                {props.label}
            </label>
            {element}
            {!inputState.isValid && inputState.isTouched && (
                <p className="text-red-600">{props.errorText}</p>
            )}
        </div>
    )
}

export default Input
