import React, { useState, useEffect, useContext } from 'react'

import avatarPlaceholder from '../../../assets/img/avatarPlaceholder.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faUser } from '@fortawesome/free-solid-svg-icons'

import { useHttpClient } from '../../hooks/httpHook'
import { AuthContext } from '../../context/authContext'

const UserSelect = props => {
    const auth = useContext(AuthContext)

    const [dropdownVisible, setDropdownVisible] = useState(false)
    const { isLoading, sendRequest } = useHttpClient()
    const [loadedUsers, setLoadedUsers] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const [users, setUsers] = useState()
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )
                setLoadedUsers(resData.users)
                setUsers(resData.users)
            } catch (err) {}
        }
        fetchUsers()
    }, [sendRequest, auth.token])

    useEffect(() => {
        if (searchValue.length <= 1) {
            setUsers(loadedUsers)
            return
        }
        const filteredUsers = users.filter(user =>
            user.username.toLowerCase().includes(searchValue)
        )
        setUsers(filteredUsers)
    }, [searchValue])

    const handleSearch = event => {
        setSearchValue(event.target.value)
    }

    const handleDropdownVisibilityChange = () => {
        setDropdownVisible(prevMode => !prevMode)
    }

    const handleUserSelectionChange = user => {
        setDropdownVisible(false)
        setSearchValue('')
        setSelectedUser(user)
        props.handleUserSelection(user)
    }

    return (
        <div className="mb-8">
            <label
                id="listbox-label"
                className="block text-sm font-medium text-gray-700"
            >
                {props.label || 'Buchen für'}
            </label>
            <div className="relative mt-1">
                <button
                    onClick={handleDropdownVisibilityChange}
                    type="button"
                    className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    {!selectedUser && (
                        <React.Fragment>
                            <span className="flex items-center">
                                <img
                                    src={avatarPlaceholder}
                                    alt=""
                                    className="flex-shrink-0 w-6 h-6 rounded-full"
                                />
                                <span className="block ml-3 font-semibold truncate text-normal">
                                    Bitte einen Benutzer auswählen
                                </span>
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                <FontAwesomeIcon icon={faArrowDown} />
                            </span>
                        </React.Fragment>
                    )}
                    {selectedUser && (
                        <React.Fragment>
                            <span className="flex items-center">
                                <img
                                    src={avatarPlaceholder}
                                    alt=""
                                    className="flex-shrink-0 w-6 h-6 rounded-full"
                                />
                                <span className="block ml-3 font-semibold truncate text-normal">
                                    {selectedUser.username}
                                </span>
                                <span className="block ml-2 text-sm text-gray-500 truncate">
                                    {selectedUser.email}
                                </span>
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                <FontAwesomeIcon icon={faArrowDown} />
                            </span>
                        </React.Fragment>
                    )}
                </button>

                <div
                    className={`${
                        dropdownVisible ? 'absolute' : 'hidden'
                    } z-10 mt-1 w-full bg-white shadow-xl rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-hidden focus:outline-none sm:text-sm`}
                >
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                ></path>
                            </svg>
                        </div>
                        <input
                            id="search"
                            type="text"
                            className="block w-full p-3 pl-10 text-sm text-gray-900 focus:ring-0 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Suchen..."
                            value={searchValue}
                            onChange={handleSearch}
                            readOnly
                            onFocus={e => e.target.removeAttribute('readonly')}
                        />
                    </div>
                    {!isLoading && users && (
                        <ul className="overflow-y-scroll max-h-60">
                            {users.map(user => (
                                <li
                                    key={user.id}
                                    className="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9 hover:bg-gray-50 hover:cursor-pointer"
                                    onClick={() =>
                                        handleUserSelectionChange(user)
                                    }
                                >
                                    <div className="flex items-center">
                                        <img
                                            src={avatarPlaceholder}
                                            alt=""
                                            className="flex-shrink-0 w-6 h-6 rounded-full"
                                        />

                                        <span className="block ml-3 font-semibold truncate text-normal">
                                            {user.username}
                                        </span>

                                        <span className="block ml-2 text-sm text-gray-500 truncate">
                                            {user.email}
                                        </span>
                                    </div>

                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-900">
                                        <FontAwesomeIcon icon={faUser} />
                                    </span>
                                </li>
                            ))}
                        </ul>
                    )}
                    <ul></ul>
                </div>
            </div>
        </div>
    )
}

export default UserSelect
