import React from 'react'

import avatarPlaceholder from '../../../assets/img/avatarPlaceholder.png'

const ProfileAvatar = props => {
    return (
        <div>
            <button
                onClick={props.onClick}
                type="button"
                className="
                    bg-white
                    rounded-full
                    flex
                    text-sm
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                "
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
            >
                <span className="sr-only">Open user menu</span>
                <img
                    className="h-8 w-8 rounded-full"
                    src={avatarPlaceholder}
                    alt=""
                />
            </button>
        </div>
    )
}

export default ProfileAvatar
