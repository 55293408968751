import { useReducer } from 'react'

const addDrinkToCart = (drink, isGuest, state) => {
    const updatedCart = [...state.cart]
    const updatedItemIndex = updatedCart.findIndex(item => item.id === drink.id)
    let updatedPrice = Number(state.totalprice)

    if (updatedItemIndex < 0) {
        updatedCart.push({ ...drink, quantity: 1 })
    } else {
        const updatedItem = {
            ...updatedCart[updatedItemIndex],
        }
        updatedItem.quantity++
        updatedCart[updatedItemIndex] = updatedItem
    }

    updatedPrice += isGuest ? Number(drink.guestprice) : Number(drink.price)

    return { ...state, cart: updatedCart, totalprice: updatedPrice }
}

const removeDrinkFromCart = (drink, isGuest, state) => {
    const updatedCart = [...state.cart]
    const updatedItemIndex = updatedCart.findIndex(item => item.id === drink.id)
    let updatedPrice = Number(state.totalprice)

    const updatedItem = {
        ...updatedCart[updatedItemIndex],
    }
    updatedItem.quantity--
    if (updatedItem.quantity <= 0) {
        updatedCart.splice(updatedItemIndex, 1)
    } else {
        updatedCart[updatedItemIndex] = updatedItem
    }
    updatedPrice -= isGuest ? Number(drink.guestprice) : Number(drink.price)
    return { ...state, cart: updatedCart, totalprice: updatedPrice }
}

const clearShoppingCart = () => {
    return { cart: [], totalprice: 0 }
}

const shoppingCartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_DRINK':
            return addDrinkToCart(action.drink, action.isGuest, state)
        case 'REMOVE_DRINK':
            return removeDrinkFromCart(action.drink, action.isGuest, state)
        case 'CLEAR_CART':
            return clearShoppingCart()
        default:
            return state
    }
}

export const useShoppingCart = () => {
    const [cartState, dispatch] = useReducer(shoppingCartReducer, {
        cart: [],
        totalprice: 0,
    })

    const addToCart = (drink, isGuest) => {
        dispatch({
            type: 'ADD_DRINK',
            drink: drink,
            isGuest: isGuest,
        })
    }

    const removeFromCart = (drink, isGuest) => {
        dispatch({ type: 'REMOVE_DRINK', drink: drink, isGuest: isGuest })
    }

    const clearShoppingCart = () => {
        dispatch({ type: 'CLEAR_CART' })
    }

    return [cartState, addToCart, removeFromCart, clearShoppingCart]
}
