import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

import { VALIDATOR_MIN } from '../../shared/util/validators'

const UserBalance = props => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler, setFormData] = useForm(
        {
            balance: {
                value: 0,
                isValid: false,
            },
        },
        false
    )

    const setUserBalanceHandler = async event => {
        event.preventDefault()
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/setbalance/${props.userId}`,
                'POST',
                JSON.stringify({
                    balance: formState.inputs.balance.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.error('Das Guthaben wurde gesetzt.')
            navigate('/users')
        } catch (e) {}
    }

    return (
        <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && (
                <div className="py-4 grid grid-cols-3">
                    <p className="block col-span-1 font-semibold">
                        Guthaben setzen
                        <span className="block col-span-1 font-semibold text-red-600">
                            ACHTUNG: Das Guthaben wird gesetzt und nicht
                            geloggt.
                        </span>
                    </p>

                    <form
                        className="space-y-2 col-span-2"
                        onSubmit={setUserBalanceHandler}
                    >
                        <Input
                            element="input"
                            id="balance"
                            type="number"
                            label="Guthaben"
                            errorText=""
                            validators={[]}
                            onInput={inputHandler}
                        />

                        <button
                            disabled={!formState.isValid}
                            type="submit"
                            className="
                                        disabled:opacity-50
                                        disabled:cursor-not-allowed
                                    w-full
                                    flex
                                    justify-center
                                    py-2
                                    px-4
                                    border border-transparent
                                    rounded-md
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    text-white
                                    bg-red-600
                                    hover:bg-red-700
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-red-500
                                "
                        >
                            Guthaben setzen
                        </button>
                    </form>
                </div>
            )}
        </React.Fragment>
    )
}

export default UserBalance
