import React, { useEffect, useContext, useState } from 'react'

import Spinner from '../../shared/components/UIElements/Spinner'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

import { importAll } from '../../utils/Methods'

const images = importAll(
    require.context('../../assets/img/products', false, /\.(png|jpe?g|svg)$/)
)

const DrinkList = props => {
    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [loadedDrinks, setLoadedDrinks] = useState()

    useEffect(() => {
        const fetchDrinks = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/drinks`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )

                setLoadedDrinks(resData.drinks)
            } catch (err) {}
        }
        fetchDrinks()
    }, [sendRequest])

    return (
        <div className="">
            <label
                id="listbox-label"
                className="block text-sm font-medium text-gray-700"
            >
                Buchungs Optionen
            </label>

            {isLoading && <Spinner />}
            <div className="flex flex-wrap mt-2">
                {!isLoading &&
                    loadedDrinks &&
                    loadedDrinks.map(drink => (
                        <div
                            onClick={() =>
                                props.onItemClick(drink, props.guestBooking)
                            }
                            key={drink.id}
                            className="flex flex-col items-center justify-center p-2 mt-4 mr-4 border-2 border-gray-300 rounded-lg cursor-pointer hover:border-indigo-600"
                        >
                            <img
                                className="h-16"
                                src={images[drink.icon]}
                                alt=""
                            />
                            <span className="mt-1 text-sm font-bold text-gray-500">
                                {drink.name}
                            </span>
                        </div>
                    ))}
                <div
                    onClick={() => props.addMoneyOption()}
                    className="flex flex-col items-center justify-center p-2 mt-4 mr-4 border-2 border-gray-300 rounded-lg cursor-pointer hover:border-indigo-600"
                >
                    <img
                        className="h-16"
                        src={images['money-bag.png']}
                        alt=""
                    />
                    <span className="mt-1 text-sm font-bold text-gray-500">
                        Geld
                    </span>
                </div>
            </div>
        </div>
    )
}

export default DrinkList
