import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import TransactionOverview from './TransactionOverview'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'
import { toast } from 'react-toastify'

const GuestOverview = () => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()
    const { isLoading: isLoadingPay, sendRequest: sendRequestPay } =
        useHttpClient()

    const [loadedGuests, setLoadedGuests] = useState()
    const [selectedGuestId, setSelectedGuestId] = useState()

    const handleGuestSelect = userId => {
        if (selectedGuestId && selectedGuestId === userId) {
            setSelectedGuestId(null)
        } else {
            setSelectedGuestId(userId)
        }
    }

    const handleGuestPayment = async guestId => {
        try {
            const res = await sendRequestPay(
                `${process.env.REACT_APP_BACKEND_URL}/api/guest/pay/${guestId}`,
                'POST',
                null,
                {
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success(res.msg)
            navigate('/redirect?uri=/guests')
        } catch (e) {}
    }

    useEffect(() => {
        const fetchGuests = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/guest`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )
                setLoadedGuests(resData.guests)
            } catch (err) {}
        }
        fetchGuests()
    }, [sendRequest])

    return (
        <div
            className="flex flex-col mt-16"
            onMouseLeave={() => setSelectedGuestId(null)}
        >
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-b border-gray-200 shadow-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        Gast
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        Betrag
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        Erstellt am
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        Aktionen
                                    </th>
                                </tr>
                            </thead>
                            {!isLoading && loadedGuests && (
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {loadedGuests.map(guest => (
                                        <React.Fragment>
                                            <tr
                                                className="hover:bg-gray-50"
                                                key={guest.id}
                                            >
                                                <td className="py-2 pl-2 pr-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="ml-4">
                                                            <div className="text-lg font-medium text-gray-900">
                                                                {guest.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="py-2 pl-4 pr-4 whitespace-nowrap">
                                                    <span
                                                        className={`px-3 py-2 inline-flex text-lg leading-5 font-bold rounded-full ${
                                                            guest.balance >= 0
                                                                ? 'bg-green-100 text-green-800'
                                                                : 'bg-red-100 text-red-800'
                                                        }`}
                                                    >
                                                        {parseFloat(
                                                            guest.balance
                                                        ).toFixed(2)}
                                                        &#8364;
                                                    </span>
                                                </td>
                                                <td className="py-2 pl-2 pr-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="ml-4">
                                                            <div className="text-lg font-medium text-gray-900">
                                                                {new Date(
                                                                    guest.created_at
                                                                ).toLocaleString(
                                                                    'de-DE',
                                                                    {
                                                                        timeZone:
                                                                            'ECT',
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-2 whitespace-nowrap">
                                                    <button
                                                        onClick={() =>
                                                            handleGuestSelect(
                                                                guest.id
                                                            )
                                                        }
                                                        className="inline-flex items-center justify-center w-full h-10 px-6 py-3 mr-4 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-700 focus:outline-none focus:ring-0 sm:w-auto"
                                                    >
                                                        Buchungen
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleGuestPayment(
                                                                guest.id
                                                            )
                                                        }
                                                        className="inline-flex items-center justify-center w-full h-10 px-6 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-indigo-700 focus:outline-none focus:ring-0 sm:w-auto"
                                                    >
                                                        Bezahlen
                                                    </button>
                                                </td>
                                            </tr>
                                            {selectedGuestId === guest.id && (
                                                <TransactionOverview
                                                    key={guest.id}
                                                    userId={guest.id}
                                                    isGuest={true}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuestOverview
