import React, { useState } from 'react'

const TabGroup = props => {
    const [activeTab, setActiveTab] = useState(props.tabs[0].key)

    return (
        <div className="flex w-full flex-col space-y-12">
            <div className="flex w-full shadow-xl rounded-full border">
                {props.tabs.map(tab => {
                    return (
                        <div
                            onClick={() => setActiveTab(tab.key)}
                            className={`w-1/2 flex flex-row justify-center p-2 hover:cursor-pointer ${
                                activeTab === tab.key
                                    ? 'bg-indigo-600 text-white font-medium'
                                    : 'bg-white text-gray-900 font-medium'
                            }`}
                        >
                            {tab.name}
                        </div>
                    )
                })}
            </div>
            {props.tabs.filter(tab => tab.key === activeTab)[0].children}
        </div>
    )
}

export default TabGroup
