export const getSessionStorageOrDefault = (key, defaultValue) => {
    if (!sessionStorage) {
        return defaultValue
    }
    const stored = sessionStorage.getItem(key)
    if (!stored) {
        return defaultValue
    }
    return JSON.parse(stored)
}

export const setToStorage = (key, value) => {
    if (!sessionStorage) {
        return false
    }
    sessionStorage.setItem(key, value)
    const stored = sessionStorage.getItem(key)
    if (!stored) {
        return false
    }
    return true
}

export const removeFromStorage = key => {
    if (!sessionStorage) {
        return false
    }
    const stored = sessionStorage.getItem(key)
    if (!stored) {
        return false
    }
    sessionStorage.removeItem(key)
    return true
}

export const getDateKey = prefix =>
    `${prefix}_${cyrb53(new Date().toISOString().split('T')[0].toString(), 2)}`

export const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i)
        h1 = Math.imul(h1 ^ ch, 2654435761)
        h2 = Math.imul(h2 ^ ch, 1597334677)
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

    return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}
