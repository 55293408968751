import React, { useState, useEffect, useContext } from 'react'

import avatarPlaceholder from '../../assets/img/avatarPlaceholder.png'
import TransactionOverview from './TransactionOverview'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

const BalanceOverview = () => {
    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()
    const [loadedUsers, setLoadedUsers] = useState()

    const [selectedUserId, setSelectedUserId] = useState()

    const handleUserSelect = userId => {
        if (selectedUserId && selectedUserId === userId) {
            setSelectedUserId(null)
        } else {
            setSelectedUserId(userId)
        }
    }

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )
                setLoadedUsers(resData.users)
            } catch (err) {}
        }
        fetchUsers()
    }, [sendRequest])

    return (
        <div className="flex flex-col mt-16 shadow-xl">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        Benutzer
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        Guthaben
                                    </th>
                                </tr>
                            </thead>
                            {!isLoading && loadedUsers && (
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {loadedUsers.map(user => (
                                        <React.Fragment>
                                            <tr
                                                className="cursor-pointer hover:bg-gray-50"
                                                key={user.id}
                                                onClick={() =>
                                                    handleUserSelect(user.id)
                                                }
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 w-10 h-10">
                                                            <img
                                                                className="w-10 h-10 rounded-full"
                                                                src={
                                                                    avatarPlaceholder
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-lg font-medium text-gray-900">
                                                                {user.username}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                {user.email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span
                                                        className={`px-3 py-2 inline-flex text-lg leading-5 font-bold rounded-full ${
                                                            user.balance >= 0
                                                                ? 'bg-green-100 text-green-800'
                                                                : 'bg-red-100 text-red-800'
                                                        }`}
                                                    >
                                                        {parseFloat(
                                                            user.balance
                                                        ).toFixed(2)}
                                                        &#8364;
                                                    </span>
                                                </td>
                                            </tr>
                                            {selectedUserId === user.id && (
                                                <TransactionOverview
                                                    userId={user.id}
                                                    isGuest={false}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BalanceOverview
