import React from 'react'
import { useNavigate, Outlet } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'

import PageHeading from '../../shared/components/UIElements/PageHeading'
import SideNavigation from '../../shared/components/UIElements/SideNavigation'
import SideEntry from '../../shared/components/UIElements/SideEntry'

const UserManagment = () => {
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <PageHeading
                title="Benutzerverwaltung"
                buttonText="Zurück"
                onClick={() => navigate('/admin')}
            />
            <div className="max-w-7xl mx-auto pb-10 lg:py-12 ">
                <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <SideNavigation>
                        <SideEntry name="Übersicht" path="/users">
                            <FontAwesomeIcon icon={faList} />
                        </SideEntry>
                        <SideEntry name="Erstellen" path="/users/create">
                            <FontAwesomeIcon icon={faPlus} />
                        </SideEntry>
                        <SideEntry name="Bearbeiten" path="/users">
                            <FontAwesomeIcon icon={faEdit} />
                        </SideEntry>
                    </SideNavigation>

                    <section className="col-span-9">
                        <Outlet />
                    </section>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserManagment
