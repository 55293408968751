import React, { useState } from 'react'
import { importAll } from '../../utils/Methods'

const images = importAll(
    require.context('../../assets/img/products', false, /\.(png|jpe?g|svg)$/)
)

const DrinkImageSelect = props => {
    return (
        <div>
            <span className="block text-sm font-medium text-gray-700">
                Icon auswählen
            </span>
            <div className="grid grid-cols-3 gap-2">
                {Object.keys(images).map(key => {
                    return (
                        <div
                            onClick={() => props.setSelectedIcon(key)}
                            key={key}
                            className={`flex flex-col justify-center items-center p-2 border-2 border-gray-300 rounded-lg cursor-pointer hover:border-indigo-600 ${
                                props.selectedIcon === key &&
                                'border-indigo-600'
                            }`}
                        >
                            <img
                                className="h-16 w-auto"
                                src={images[key]}
                                alt=""
                            />
                            <span className="mt-1 text-sm text-gray-500 font-bold">
                                {key.split('.')[0]}
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DrinkImageSelect
