import React, { useEffect, useContext, useState } from 'react'

import Spinner from '../../shared/components/UIElements/Spinner'
import TextHeader from '../../shared/components/UIElements/TextHeader'

import GuestBooking from '../components/GuestBooking'
import { AuthContext } from '../../shared/context/authContext'
import { useHttpClient } from '../../shared/hooks/httpHook'
import AddGuest from '../components/AddGuest'
import GuestOverview from '../components/GuestOverview'

const GuestTerminal = () => {
    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [loadedContext, setLoadedContext] = useState()

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/${auth.userId}`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )

                setLoadedContext(resData.user)
            } catch (err) {}
        }
        fetchUser()
    }, [sendRequest, auth.userId, auth.token])

    return (
        <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && loadedContext && (
                <div>
                    <TextHeader text="Gästebereich" />
                    <AddGuest />
                    <GuestBooking />
                    <GuestOverview />
                </div>
            )}
        </React.Fragment>
    )
}

export default GuestTerminal
