import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'
import EmptyState from '../../shared/components/UIElements/EmptyState'
import UserBookings from '../components/UserBookings'
import UserTransaction from '../components/UserTransaction'
import UserBalance from '../components/UserBalance'
import IdentityToken from '../components/IdentityToken'
import UserDelete from '../components/UserDelete'

const EditUser = () => {
    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()
    const [loadedUser, setLoadedUser] = useState()
    const { userId } = useParams()

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )
                setLoadedUser(resData.user)
            } catch (e) {}
        }
        fetchUser()
    }, [sendRequest, userId])

    if (!loadedUser) {
        return (
            <EmptyState
                title="Kein Benutzer gefunden."
                description="Bitte versuche es später erneut."
            />
        )
    }

    return (
        <div className="flex flex-col py-8 px-6 space-y-4 shadow bg-white border-gray-200 sm:rounded-lg divide-y-2 ">
            {!isLoading && loadedUser && (
                <React.Fragment>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            <span className="text-gray-500">Benutzer:</span>
                            &nbsp;
                            {loadedUser.username}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Hier ganz den Benutzer bearbeiten.
                        </p>
                    </div>
                    {loadedUser.role === 'user' && (
                        <IdentityToken userId={loadedUser.id} />
                    )}

                    <UserTransaction userId={loadedUser.id} />
                    {auth.role === 'owner' && (
                        <React.Fragment>
                            <UserBalance userId={loadedUser.id} />
                            <UserDelete userId={loadedUser.id} />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <UserBookings />
        </div>
    )
}

export default EditUser
