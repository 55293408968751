import React from 'react'

import ResetPassword from './ResetPassword'

const AccountSettings = () => {
    return (
        <div className="flex flex-col py-8 px-6 space-y-4 shadow bg-white border-gray-200 sm:rounded-lg divide-y-2 ">
            <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Einstellungen
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                    Hier kannst du einfach die Daten zu deinem Account ändern.
                </p>
            </div>
            <ResetPassword />
        </div>
    )
}

export default AccountSettings
