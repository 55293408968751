import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons'

const CartOverview = props => {
    return (
        <div className="mt-8">
            <label
                id="listbox-label"
                className="block text-sm font-medium text-gray-700"
            >
                Warenkorb
            </label>
            <dd className="mt-1 text-sm text-gray-900">
                <ul className="border border-gray-200 divide-y divide-gray-200 rounded-md">
                    {props.cartState &&
                        props.cartState.cart.map(item => (
                            <li
                                key={item.id}
                                className="flex items-center justify-between py-3 pl-3 pr-4 text-base text-gray-900"
                            >
                                <div className="flex items-center flex-1 w-0">
                                    <FontAwesomeIcon
                                        className="text-gray-500"
                                        icon={faDotCircle}
                                    />
                                    <span className="ml-2 truncate">
                                        {item.quantity}&nbsp;x
                                    </span>
                                    <span className="flex-1 w-0 ml-2 font-semibold truncate">
                                        {item.name}
                                    </span>
                                    <span className="flex-1 w-0 ml-2 font-semibold truncate">
                                        {(
                                            item.quantity *
                                            (props.guestBooking
                                                ? item.guestprice
                                                : item.price)
                                        ).toFixed(2)}
                                        &#8364;
                                    </span>
                                </div>
                                <div className="flex-shrink-0 ml-4">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            props.onItemClick(
                                                item,
                                                props.guestBooking
                                            )
                                        }
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                        Entfernen
                                    </button>
                                </div>
                            </li>
                        ))}
                </ul>
                {props.cartState && (
                    <p className="mt-2 mr-1 text-lg font-medium text-right">
                        Gesamt:{' '}
                        <span className="font-bold">
                            {props.cartState.totalprice.toFixed(2)}&#8364;
                        </span>
                    </p>
                )}
            </dd>
        </div>
    )
}

export default CartOverview
