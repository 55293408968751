import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'
import Modal from '../../shared/components/UIElements/Modal'

import { VALIDATOR_MIN } from '../../shared/util/validators'

const UserDelete = props => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const showDeleteWarningHandler = () => setShowConfirmModal(true)

    const cancelDeleteHandler = () => setShowConfirmModal(false)

    const confirmDeleteHandler = async () => {
        //setShowConfirmModal(false)
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/${props.userId}`,
                'DELETE',
                null,
                { Authorization: `Bearer ${auth.token}` }
            )
            toast.warn('Benutzer wurde gelöscht')
            navigate('/users')
        } catch (err) {}
    }

    return (
        <React.Fragment>
            <Modal
                show={showConfirmModal}
                onCancel={cancelDeleteHandler}
                title="Benutzer löschen?"
                onClick={confirmDeleteHandler}
                buttonText="Löschen"
            >
                <p>Soll der Benutzer wirklick gelöscht werden?</p>
            </Modal>
            {isLoading && <Spinner />}
            {!isLoading && (
                <div className="py-4 grid grid-cols-3">
                    <p className="block col-span-1 font-semibold">
                        Benutzer löschen
                        <span className="block col-span-1 font-semibold text-red-600">
                            ACHTUNG: Der Benutzer wird endgültig gelöscht.
                        </span>
                    </p>

                    <form className="space-y-2 col-span-2">
                        <button
                            onClick={showDeleteWarningHandler}
                            type="button"
                            className="
                                        disabled:opacity-50
                                        disabled:cursor-not-allowed
                                    w-full
                                    flex
                                    justify-center
                                    py-2
                                    px-4
                                    border border-transparent
                                    rounded-md
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    text-white
                                    bg-red-600
                                    hover:bg-red-700
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-red-500
                                "
                        >
                            Benutzer löschen
                        </button>
                    </form>
                </div>
            )}
        </React.Fragment>
    )
}

export default UserDelete
