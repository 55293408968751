import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Spinner from '../../shared/components/UIElements/Spinner'
import EmptyState from '../../shared/components/UIElements/EmptyState'
import Modal from '../../shared/components/UIElements/Modal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

const TransactionOverview = props => {
    const navigate = useNavigate()
    const auth = useContext(AuthContext)
    const { isLoading, sendRequest } = useHttpClient()
    const {
        isLoading: isLoadingTransaction,
        sendRequest: sendRequestTransaction,
    } = useHttpClient()
    const [loadedBookings, setLoadedBookings] = useState()
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [selectedTransactions, setSelectedTransactions] = useState()

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const resData = await sendRequest(
                    props.isGuest
                        ? `${process.env.REACT_APP_BACKEND_URL}/api/guest/transactions/${props.userId}`
                        : `${process.env.REACT_APP_BACKEND_URL}/api/transactions/${props.userId}`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )

                setLoadedBookings(resData.bookings)
            } catch (err) {}
        }
        fetchBookings()
    }, [sendRequest, props.isGuest])

    const deleteGuestBooking = async () => {
        try {
            await sendRequestTransaction(
                `${process.env.REACT_APP_BACKEND_URL}/api/guest/transaction/${selectedTransactions.id}`,
                'DELETE',
                null,
                {
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            navigate('/redirect?uri=/guests')
        } catch (e) {}
    }

    const showDeleteWarningHandler = () => setShowConfirmModal(true)

    const cancelDeleteHandler = () => setShowConfirmModal(false)

    return (
        <React.Fragment>
            <Modal
                show={showConfirmModal}
                onCancel={cancelDeleteHandler}
                title="Buchung löschen?"
                onClick={deleteGuestBooking}
                buttonText="Löschen"
            >
                <p>Soll die Buchung wirklick gelöscht werden?</p>
            </Modal>

            <tr>
                <td className="w-full pb-8" colspan={4}>
                    <div className="flex flex-col mt-5">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden border-gray-200">
                                    <table className="min-w-full table-fixed">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                                >
                                                    #
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                                >
                                                    Datum
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                                >
                                                    Bechreibung
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                                >
                                                    Betrag
                                                </th>
                                                {!props.isGuest ? (
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                                    >
                                                        Typ
                                                    </th>
                                                ) : (
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase"
                                                    >
                                                        Aktionen
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        {!isLoading && loadedBookings && (
                                            <tbody className="w-full bg-white divide-y divide-gray-300">
                                                {loadedBookings.map(
                                                    (booking, index) => (
                                                        <tr
                                                            className="font-normal bg-gray-50"
                                                            key={booking.id}
                                                        >
                                                            <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                                {index + 1}
                                                            </td>
                                                            <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                                {new Date(
                                                                    booking.created_at
                                                                ).toLocaleString(
                                                                    'de-DE',
                                                                    {
                                                                        timeZone:
                                                                            'ECT',
                                                                    }
                                                                )}
                                                            </td>
                                                            <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                                {
                                                                    booking.description
                                                                }
                                                            </td>
                                                            <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                                <span
                                                                    className={`px-3 py-1 inline-flex text-sm leading-5 font-bold rounded-full ${
                                                                        props.isGuest
                                                                            ? 'bg-red-100 text-red-800'
                                                                            : booking.type ===
                                                                              'payment'
                                                                            ? 'bg-red-100 text-red-800'
                                                                            : 'bg-green-100 text-green-800'
                                                                    }`}
                                                                >
                                                                    {(booking.type ===
                                                                        'payment' ||
                                                                        props.isGuest) && (
                                                                        <span>
                                                                            -
                                                                        </span>
                                                                    )}
                                                                    {booking.type ===
                                                                        'deposit' && (
                                                                        <span>
                                                                            +
                                                                        </span>
                                                                    )}
                                                                    {parseFloat(
                                                                        booking.amount
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                    &#8364;
                                                                </span>
                                                            </td>
                                                            {!props.isGuest ? (
                                                                <td className="px-6 py-4 font-medium text-gray-900 text-normal">
                                                                    {booking.type ===
                                                                        'payment' &&
                                                                        'Zahlung'}
                                                                    {booking.type ===
                                                                        'deposit' &&
                                                                        'Einzahlnung'}
                                                                </td>
                                                            ) : (
                                                                <td className="px-6 py-4 text-normal">
                                                                    <button
                                                                        className="text-gray-900"
                                                                        onClick={() => {
                                                                            setSelectedTransactions(
                                                                                booking
                                                                            )
                                                                            showDeleteWarningHandler()
                                                                        }}
                                                                    >
                                                                        <span className="mr-2">
                                                                            Delete
                                                                        </span>
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrashAlt
                                                                            }
                                                                        />
                                                                    </button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        )}
                                    </table>

                                    {isLoading && <Spinner />}
                                    {!isLoading && !loadedBookings && (
                                        <EmptyState title="Keine Buchungen gefunden" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default TransactionOverview
