import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

import { VALIDATOR_REQUIRE } from '../../shared/util/validators'

const CreateUser = () => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler] = useForm(
        {
            username: {
                value: '',
                isValid: false,
            },
        },
        false
    )

    const createUserSubmitHandler = async event => {
        event.preventDefault()
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/user`,
                'POST',
                JSON.stringify({
                    username: formState.inputs.username.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success('Benutzer wurde erstellt')
            navigate('/users')
        } catch (e) {}
    }

    return (
        <div className="flex flex-col py-8 px-6 space-y-4 shadow bg-white border-gray-200 sm:rounded-lg divide-y-2 ">
            <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Account erstellen
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                    Erstelle einfach einen neuen Account
                </p>
            </div>
            {isLoading && <Spinner />}
            {!isLoading && (
                <div className="py-4 grid grid-cols-3">
                    <p className="block col-span-1 font-semibold">
                        Account hinzufügen
                    </p>
                    <form
                        className="space-y-2 col-span-2"
                        onSubmit={createUserSubmitHandler}
                    >
                        <Input
                            element="input"
                            id="username"
                            type="text"
                            label="Username"
                            errorText="Das Feld darf nicht leer sein!"
                            validators={[VALIDATOR_REQUIRE()]}
                            onInput={inputHandler}
                        />

                        <button
                            disabled={!formState.isValid}
                            type="submit"
                            className="
                                    disabled:opacity-50
                                    disabled:cursor-not-allowed
                                   w-full
                                   flex
                                   justify-center
                                   py-2
                                   px-4
                                   border border-transparent
                                   rounded-md
                                   shadow-sm
                                   text-sm
                                   font-medium
                                   text-white
                                   bg-indigo-600
                                   hover:bg-indigo-700
                                   focus:outline-none
                                   focus:ring-2
                                   focus:ring-offset-2
                                   focus:ring-indigo-500
                               "
                        >
                            Benutzer hinzufügen
                        </button>
                    </form>
                </div>
            )}
        </div>
    )
}

export default CreateUser
