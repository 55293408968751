import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import PageHeading from '../../shared/components/UIElements/PageHeading'
import WithAdminAuth from '../../shared/hoc/WithAdminAuth'
import { useAuth } from '../../shared/hooks/authHook'
import { getDateKey, removeFromStorage } from '../../shared/util/sessionStorage'
const Dashboard = () => {
    const navigate = useNavigate()
    const authContext = useAuth()

    const lockPage = () => {
        if (removeFromStorage(getDateKey('isv'))) {
            navigate('/redirect?uri=/admin')
        }
    }

    return (
        <React.Fragment>
            <PageHeading
                title="Admin Dashboard"
                buttonText="Sperren"
                onClick={lockPage}
            />

            <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-3">
                <button
                    onClick={() => navigate('/users')}
                    className="flex items-center justify-center p-8 bg-white rounded-lg shadow"
                >
                    <p className="text-lg font-bold">Users</p>
                </button>
                <button
                    onClick={() => navigate('/drinks')}
                    className="flex items-center justify-center p-8 bg-white rounded-lg shadow"
                >
                    <p className="text-lg font-bold">Drinks</p>
                </button>
                {['owner'].includes(authContext.role) && (
                    <>
                        <button
                            onClick={() => navigate('/changepincode')}
                            className="flex items-center justify-center p-8 bg-white rounded-lg shadow"
                        >
                            <p className="text-lg font-bold">Pincode</p>
                        </button>
                        <button
                            onClick={() => navigate('/changepassword')}
                            className="flex items-center justify-center p-8 bg-white rounded-lg shadow"
                        >
                            <p className="text-lg font-bold">Password</p>
                        </button>
                    </>
                )}
            </div>

            <Outlet />
        </React.Fragment>
    )
}

export default WithAdminAuth(Dashboard)
