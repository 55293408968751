import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { AuthContext } from '../../context/authContext'

const ProfileMenu = props => {
    const auth = useContext(AuthContext)

    return (
        <div
            id="profileMenu"
            onMouseLeave={props.onLeave}
            className={`
                origin-top-right
                right-0
                mt-2
                w-48
                rounded-md
                shadow-lg
                py-1
                bg-white
                ring-1 ring-black ring-opacity-5
                focus:outline-none
                z-10
                ${props.visible ? 'absolute' : 'hidden'}
                `}
        >
            {!['terminal'].includes(auth.role) && (
                <NavLink
                    to="/account"
                    className="block px-4 py-2 text-sm text-gray-700"
                >
                    Deine Account
                </NavLink>
            )}

            <button
                onClick={auth.logout}
                className="block px-4 py-2 text-sm text-gray-700"
            >
                Ausloggen
            </button>
        </div>
    )
}

export default ProfileMenu
