import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

import {
    VALIDATOR_MINLENGTH,
    VALIDATOR_REQUIRE,
} from '../../shared/util/validators'

const ResetPassword = () => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler, setFormData] = useForm(
        {
            oldpassword: {
                value: '',
                isValid: false,
            },
            newpassword: {
                value: '',
                isValid: false,
            },
            confirmationpassword: {
                value: '',
                isValid: false,
            },
        },
        false
    )

    const changePasswordSubmitHandler = async event => {
        event.preventDefault()
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/changepassword`,
                'POST',
                JSON.stringify({
                    oldpassword: formState.inputs.oldpassword.value,
                    newpassword: formState.inputs.newpassword.value,
                    confirmationpassword:
                        formState.inputs.confirmationpassword.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success('Dein Password wurde erfolgreich geändert.')
            navigate('/account')
        } catch (e) {}
    }

    return (
        <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && (
                <div className="grid grid-cols-3 py-4">
                    <p className="block col-span-1 font-semibold">
                        Neues Passwort
                    </p>
                    <form
                        className="col-span-2 space-y-2"
                        onSubmit={changePasswordSubmitHandler}
                    >
                        <Input
                            element="input"
                            id="oldpassword"
                            type="password"
                            label="Altes Passwort"
                            errorText="Das Feld darf nicht leer sein!"
                            validators={[VALIDATOR_REQUIRE()]}
                            onInput={inputHandler}
                        />
                        <Input
                            element="input"
                            id="newpassword"
                            type="password"
                            label="Neues Passwort"
                            errorText="Das Feld darf nicht leer sein!"
                            validators={[VALIDATOR_MINLENGTH(6)]}
                            onInput={inputHandler}
                        />
                        <Input
                            element="input"
                            id="confirmationpassword"
                            type="password"
                            label="Passwort bestätigen"
                            errorText="Das Feld darf nicht leer sein!"
                            validators={[VALIDATOR_MINLENGTH(6)]}
                            onInput={inputHandler}
                        />
                        <button
                            disabled={!formState.isValid}
                            type="submit"
                            className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm  disabled:opacity-50 disabled:cursor-not-allowed hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Passwort ändern
                        </button>
                    </form>
                </div>
            )}
        </React.Fragment>
    )
}

export default ResetPassword
