import React, { useEffect, useContext, useState } from 'react'

import Spinner from '../../shared/components/UIElements/Spinner'
import TextHeader from '../../shared/components/UIElements/TextHeader'
import PersonalBookings from '../components/PersonalBookings'
import PersonalStats from '../components/PersonalStats'

import { AuthContext } from '../../shared/context/authContext'
import { useHttpClient } from '../../shared/hooks/httpHook'

const PersonalOverview = () => {
    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [loadedUser, setLoadedUser] = useState()

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/${auth.userId}`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )
                setLoadedUser(resData.user)
            } catch (err) {}
        }
        fetchUser()
    }, [sendRequest, auth.userId, auth.token])

    return (
        <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && loadedUser && (
                <React.Fragment>
                    <TextHeader text="Willkommen" name={loadedUser.username} />
                    <PersonalStats balance={loadedUser.balance} />
                    <PersonalBookings />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default PersonalOverview
