import React, { useState, useContext } from 'react'

import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { AuthContext } from '../../shared/context/authContext'
import { useHttpClient } from '../../shared/hooks/httpHook'
import Modal from '../../shared/components/UIElements/Modal'

const UserBookingItem = props => {
    const { isLoading, sendRequest } = useHttpClient()

    const auth = useContext(AuthContext)

    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const showDeleteWarningHandler = () => setShowConfirmModal(true)

    const cancelDeleteHandler = () => setShowConfirmModal(false)

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false)
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/transactions/${props.booking.id}`,
                'DELETE',
                null,
                { Authorization: `Bearer ${auth.token}` }
            )
            toast.warn('Buchung wurde gelöscht')
            props.onDelete(props.booking.id)
        } catch (err) {}
    }

    return (
        <React.Fragment>
            <Modal
                show={showConfirmModal}
                onCancel={cancelDeleteHandler}
                title="Getränk löschen?"
                onClick={confirmDeleteHandler}
                buttonText="Löschen"
            >
                <p>Soll die Buchung gelöscht werden?</p>
            </Modal>
            <tr className="hover:bg-gray-50 font-normal" key={props.booking.id}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    {new Date(props.booking.created_at).toLocaleString(
                        'de-DE',
                        {
                            timeZone: 'ECT',
                        }
                    )}
                </td>
                <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    {props.booking.description}
                </td>
                <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    <span
                        className={`px-3 py-1 inline-flex text-sm leading-5 font-medium rounded-full ${
                            props.booking.type === 'payment'
                                ? 'bg-red-100 text-red-800'
                                : 'bg-green-100 text-green-800'
                        }`}
                    >
                        {props.booking.type === 'payment' && <span>-</span>}
                        {parseFloat(props.booking.amount).toFixed(2)}
                        &#8364;
                    </span>
                </td>
                <td className="px-6 py-4 text-sm font-medium  text-gray-900">
                    {props.booking.type === 'payment' && 'Zahlung'}
                </td>
                <td className="px-6 py-4 text-right text-normal">
                    <button
                        className="text-red-600"
                        onClick={showDeleteWarningHandler}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <span className="sr-only">Delete</span>
                    </button>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default UserBookingItem
