import React, { useContext, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import ImageUpload from '../../shared/components/FormElements/ImageUpload'
import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

import {
    VALIDATOR_MIN,
    VALIDATOR_MINLENGTH,
} from '../../shared/util/validators'
import DrinkImageSelect from './DrinkImageSelect'

const CreateDrink = () => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [selectedIcon, setSelectedIcon] = useState()

    const handleIconClick = key => {
        setSelectedIcon(key)
    }

    const [formState, inputHandler] = useForm(
        {
            name: {
                value: '',
                isValid: false,
            },
            price: {
                value: 0.0,
                isValid: false,
            },
            guestprice: {
                value: 0.0,
                isValid: false,
            },
        },
        false
    )

    const createDrinkSubmitHandler = async event => {
        event.preventDefault()
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/drinks`,
                'POST',
                JSON.stringify({
                    name: formState.inputs.name.value,
                    price: formState.inputs.price.value,
                    guestprice: formState.inputs.guestprice.value,
                    icon: selectedIcon,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success('Getränk erfolgreich erstellt')
            navigate('/drinks')
        } catch (e) {}
    }

    return (
        <React.Fragment>
            <div className="flex flex-col py-8 px-6 space-y-4 shadow bg-white border-gray-200 sm:rounded-lg divide-y-2 ">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Getränk erstellen
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                        Hier kansnt du ganz einfach ein neues Getränk erstellen.
                    </p>
                </div>
                {isLoading && <Spinner />}
                {!isLoading && (
                    <div className="py-4 grid grid-cols-3">
                        <p className="block col-span-1 font-semibold"></p>
                        <form
                            className="space-y-2 col-span-2"
                            onSubmit={createDrinkSubmitHandler}
                        >
                            <Input
                                element="input"
                                id="name"
                                type="text"
                                label="Getränkename"
                                errorText="Das Feld darf nicht leer sein!"
                                validators={[VALIDATOR_MINLENGTH(2)]}
                                onInput={inputHandler}
                            />
                            <Input
                                element="input"
                                id="price"
                                type="number"
                                min={0}
                                label="Preis"
                                errorText="Der Preis muss über 0€ liegen."
                                validators={[VALIDATOR_MIN(0)]}
                                onInput={inputHandler}
                            />
                            <Input
                                element="input"
                                id="guestprice"
                                min={0}
                                type="number"
                                label="Gastpreis"
                                errorText="Der Preis muss über 0€ liegen."
                                validators={[VALIDATOR_MIN(0)]}
                                onInput={inputHandler}
                            />
                            <DrinkImageSelect
                                selectedIcon={selectedIcon}
                                setSelectedIcon={handleIconClick}
                            />
                            <button
                                disabled={!formState.isValid}
                                type="submit"
                                className="
                                    disabled:opacity-50
                                    disabled:cursor-not-allowed
                                    
                                w-full
                                flex
                                justify-center
                                py-2
                                px-4
                                border border-transparent
                                rounded-md
                                shadow-sm
                                text-sm
                                font-medium
                                text-white
                                bg-indigo-600
                                hover:bg-indigo-700
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-indigo-500
                            "
                            >
                                Getränk erstellen
                            </button>
                        </form>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default CreateDrink
