import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

import {
    VALIDATOR_MINLENGTH,
    VALIDATOR_REQUIRE,
} from '../../shared/util/validators'

const AccountEnablement = () => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler] = useForm(
        {
            identitytoken: {
                value: '',
                isValid: false,
            },
            email: {
                value: '',
                isValid: false,
            },
            password: {
                value: '',
                isValid: false,
            },
            confirmationpassword: {
                value: '',
                isValid: false,
            },
        },
        false
    )

    const activateAccountSubmitHandler = async event => {
        event.preventDefault()
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/activate`,
                'POST',
                JSON.stringify({
                    identitytoken: formState.inputs.identitytoken.value,
                    email: formState.inputs.email.value,
                    password: formState.inputs.password.value,
                    confirmationpassword:
                        formState.inputs.confirmationpassword.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success('Konto wurde aktiviert')
            navigate('/')
        } catch (e) {}
    }

    return (
        <React.Fragment>
            <div className="flex flex-col py-8 px-6 space-y-4 shadow bg-white border-gray-200 sm:rounded-lg divide-y-2 ">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Konto aktivieren
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                        Hier kannst du dein Konto freischalten. Du benötigst nur
                        deinen Identitäts-Token.
                    </p>
                </div>
                <div className="py-4">
                    <form
                        className="space-y-2 col-span-2"
                        onSubmit={activateAccountSubmitHandler}
                    >
                        <Input
                            element="input"
                            id="identitytoken"
                            type="text"
                            label="Identitäts-Token"
                            errorText="Das Feld darf nicht leer sein!"
                            validators={[VALIDATOR_REQUIRE()]}
                            onInput={inputHandler}
                        />
                        <Input
                            element="input"
                            id="email"
                            type="email"
                            label="Email"
                            errorText="Das Feld darf nicht leer sein!"
                            validators={[VALIDATOR_REQUIRE()]}
                            onInput={inputHandler}
                        />
                        <Input
                            element="input"
                            id="password"
                            type="password"
                            label="Passwort"
                            errorText="Das Feld darf nicht leer sein!"
                            validators={[VALIDATOR_MINLENGTH(6)]}
                            onInput={inputHandler}
                        />
                        <Input
                            element="input"
                            id="confirmationpassword"
                            type="password"
                            label="Passwort bestätigen"
                            errorText="Das Feld darf nicht leer sein!"
                            validators={[VALIDATOR_MINLENGTH(6)]}
                            onInput={inputHandler}
                        />
                        <button
                            disabled={!formState.isValid}
                            type="submit"
                            className="
                                    
                                        disabled:opacity-50
                                        disabled:cursor-not-allowed
                                    w-full
                                    flex
                                    justify-center
                                    py-2
                                    px-4
                                    border border-transparent
                                    rounded-md
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    text-white
                                    bg-indigo-600
                                    hover:bg-indigo-700
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-indigo-500
                                "
                        >
                            Konto aktivieren
                        </button>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccountEnablement
