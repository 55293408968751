import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'
import UserBookingItem from './UserBookingItem'

const UserBookings = () => {
    const navigate = useNavigate()
    const { userId } = useParams()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()
    const [loadedBookings, setLoadedBookings] = useState()

    const pageSize = 5
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/transactions/${userId}`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.token}`,
                    }
                )

                setLoadedBookings(resData.bookings)
            } catch (err) {}
        }
        fetchBookings()
    }, [sendRequest])

    const bookingDeleteHandler = bookingDeleteId => {
        setLoadedBookings(prevBooking =>
            prevBooking.filter(booking => booking.id !== bookingDeleteId)
        )
    }

    const paginate = (array, page_size, page_number) => {
        return array.slice(
            (page_number - 1) * page_size,
            page_number * page_size
        )
    }

    const pageNumbers = () => {
        let pagesNumbers = Math.floor(loadedBookings.length / pageSize)
        loadedBookings.length % pageSize > 0 && pagesNumbers++
        return pagesNumbers
    }

    const nextPage = () =>
        currentPage < pageNumbers() && setCurrentPage(currentPage + 1)

    const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1)

    return (
        <div className="">
            <p className="block col-span-1 font-semibold ml-1 my-4">
                Letzen Buchungen
            </p>
            <div className=" overflow-hidden border-gray-200 ">
                <table className="min-w-full divide-y divide-gray-200 table-fixed border-separate">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                            >
                                Datum
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                            >
                                Bechreibung
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                            >
                                Betrag
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                            >
                                Typ
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    {!isLoading && loadedBookings && (
                        <tbody className="bg-white divide-y divide-gray-200">
                            {paginate(
                                loadedBookings,
                                pageSize,
                                currentPage
                            ).map(booking => (
                                <UserBookingItem
                                    booking={booking}
                                    onDelete={bookingDeleteHandler}
                                />
                            ))}
                        </tbody>
                    )}
                </table>
            </div>
            <div className="flex mt-4 justify-between items-center">
                <button
                    class="border border-gray-900 bg-white text-gray-900  rounded-sm font-bold py-3 px-4 ml-2 flex items-center"
                    onClick={prevPage}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>

                <span class="border border-gray-900 bg-white text-gray-900 rounded-sm font-bold py-2 px-4 ml-2 flex items-center">
                    {currentPage}
                </span>
                <button
                    class="border border-gray-900 bg-white text-gray-900  rounded-sm font-bold py-3 px-4 ml-2 flex items-center"
                    onClick={nextPage}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
        </div>
    )
}

export default UserBookings
