import React, { useState, useContext } from 'react'

import Input from '../../shared/components/FormElements/Input'
import backgroundImage from '../../assets/img/billiardTable.jpg'
import logo from '../../assets/img/billard.png'
import Spinner from '../../shared/components/UIElements/Spinner'
import { AuthContext } from '../../shared/context/authContext'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'

import {
    VALIDATOR_EMAIL,
    VALIDATOR_MINLENGTH,
    VALIDATOR_REQUIRE,
} from '../../shared/util/validators'

const Auth = () => {
    const auth = useContext(AuthContext)
    const [isLoginMode, setIsLoginMode] = useState(true)
    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler, setFormData] = useForm({
        email: {
            value: '',
            isValid: false,
        },
        password: {
            value: '',
            isValid: false,
        },
    })

    const switchModeHandler = () => {
        if (!isLoginMode) {
            setFormData(
                { ...formState.inputs, username: undefined },
                formState.inputs.email.isValid &&
                    formState.inputs.password.isValid
            )
        } else {
            setFormData(
                {
                    ...formState.inputs,
                    username: { value: '', isValid: false },
                },
                false
            )
        }
        setIsLoginMode(prevMode => !prevMode)
    }

    const authSubmitHandler = async event => {
        event.preventDefault()

        if (isLoginMode) {
            try {
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/login`,
                    'POST',
                    JSON.stringify({
                        email: formState.inputs.email.value,
                        password: formState.inputs.password.value,
                    }),
                    {
                        'Content-Type': 'application/json',
                    }
                )
                auth.login(resData.userId, resData.token, resData.role)
            } catch (err) {}
        } else {
            try {
                const formData = new FormData()
                formData.append('email', formState.inputs.email.value)
                formData.append('username', formState.inputs.username.value)
                formData.append('password', formState.inputs.password.value)
                //formData.append('image', formState.inputs.image.value)
                const resData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/signup`,
                    'POST',
                    formData
                )

                auth.login(resData.userId, resData.token, resData.role)
            } catch (err) {}
        }
    }

    return (
        <div className="flex min-h-screen bg-white">
            <div
                className="flex flex-col justify-center flex-1 px-4 py-12  sm:px-6 lg:flex-none lg:px-20 xl:px-24"
            >
                <div className="w-full max-w-sm mx-auto lg:w-96">
                    <div>
                        <img
                            className="w-auto h-12"
                            src={logo}
                            alt="Workflow"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                            {isLoginMode ? 'Anmelden' : 'Registrieren'}
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                            oder{' '}
                            <button
                                onClick={switchModeHandler}
                                className="font-medium text-indigo-600  hover:text-indigo-500"
                            >
                                {isLoginMode
                                    ? 'jetzt Konto erstellen'
                                    : 'mit ihrem Konto anmelden'}
                            </button>
                        </p>
                    </div>

                    {isLoading && <Spinner />}
                    {!isLoading && (
                        <div className="mt-8">
                            <div className="mt-6">
                                <form
                                    onSubmit={authSubmitHandler}
                                    className="space-y-6"
                                >
                                    {!isLoginMode && (
                                        <Input
                                            element="input"
                                            id="username"
                                            type="text"
                                            label="Benutzername"
                                            validators={[VALIDATOR_REQUIRE()]}
                                            errorText="Please enter a name"
                                            onInput={inputHandler}
                                        />
                                    )}
                                    <Input
                                        element="input"
                                        id="email"
                                        type="email"
                                        label="E-Mail"
                                        validators={[VALIDATOR_EMAIL()]}
                                        errorText="Please enter a valid email adress."
                                        onInput={inputHandler}
                                    />
                                    <Input
                                        element="input"
                                        id="password"
                                        type="password"
                                        label="Password"
                                        validators={[VALIDATOR_MINLENGTH(6)]}
                                        errorText="Please enter more than 6 chars."
                                        onInput={inputHandler}
                                    />

                                    <div className="flex items-center justify-end">
                                        <div className="text-sm">
                                            <button
                                                className="font-medium text-indigo-600 line-through cursor-not-allowed  hover:text-indigo-500"
                                            >
                                                Forgot your password?
                                            </button>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            disabled={!formState.isValid}
                                            type="submit"
                                            className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm  disabled:opacity-50 disabled:cursor-not-allowed hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            {isLoginMode
                                                ? 'Anmelden'
                                                : 'Registrieren'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="relative flex-1 hidden w-0 lg:block">
                <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src={backgroundImage}
                    alt=""
                />
            </div>
        </div>
    )
}

export default Auth
