import React, { useContext, useEffect, useState } from 'react'

import { toast } from 'react-toastify'

import Input from '../../shared/components/FormElements/Input'
import UserSelect from '../../shared/components/FormElements/UserSelect'
import Spinner from '../../shared/components/UIElements/Spinner'
import DrinkList from './DrinkList'

import { AuthContext } from '../../shared/context/authContext'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { useShoppingCart } from '../../shared/hooks/shoppingCartHook'
import CartOverview from '../components/CartOverview'

import { VALIDATOR_MIN } from '../../shared/util/validators'

const BookForOther = () => {
    const auth = useContext(AuthContext)

    const [selectedUser, setSelectedUser] = useState()
    const [showMoneyOption, setShowMoneyOption] = useState(false)

    const [cartState, addToCart, removeFromCart, clearShoppingCart] =
        useShoppingCart()

    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler, setFormData] = useForm(
        {
            additionalMoney: {
                value: 0,
                isValid: true,
            },
        },
        true
    )

    const handleUserSelection = user => {
        setSelectedUser(user)
    }

    const addMoneyOption = () => setShowMoneyOption(true)

    const addMoneyToCart = () => {
        setShowMoneyOption(false)
        if (
            formState.inputs.additionalMoney.value &&
            formState.inputs.additionalMoney.value > 0
        ) {
            addToCart(
                {
                    id: `${Math.ceil(Math.random() * 1500)}_money=${
                        formState.inputs.additionalMoney.value
                    }`,
                    name: 'Betrag ( Euro )',
                    price: formState.inputs.additionalMoney.value,
                    guestprice: formState.inputs.additionalMoney.value,
                },
                false
            )
        }
    }

    const bookDrinkSubmitHandler = async event => {
        event.preventDefault()
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/transactions/admin/${selectedUser.id}`,
                'POST',
                JSON.stringify({
                    cart: cartState.cart,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            clearShoppingCart()
            toast.success('Buchung war erfolgreich')
        } catch (e) {}
    }

    useEffect(() => {
        if (!formState.inputs.additionalMoney.isValid) {
            setFormData({
                ...formState.inputs,
                additionalMoney: {
                    value: 0,
                    isValid: formState.inputs.additionalMoney.value >= 0,
                },
            })
        }
    }, [formState])

    return (
        <div className="bg-gray-100">
            <div className="">
                <div className="relative bg-white shadow-xl">
                    <h2 className="sr-only">Terminal</h2>

                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        <div className="relative px-6 py-10 overflow-hidden bg-indigo-700 sm:px-10 xl:p-12">
                            <div
                                className="absolute inset-0 pointer-events-none sm:hidden"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width="343"
                                    height="388"
                                    viewBox="0 0 343 388"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                        fill="url(#linear1)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear1"
                                            x1="254.553"
                                            y1="107.554"
                                            x2="961.66"
                                            y2="814.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"></stop>
                                            <stop
                                                offset="1"
                                                stopColor="#fff"
                                                stopOpacity="0"
                                            ></stop>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="absolute top-0 bottom-0 right-0 hidden w-1/2 pointer-events-none sm:block lg:hidden"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width="359"
                                    height="339"
                                    viewBox="0 0 359 339"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                        fill="url(#linear2)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear2"
                                            x1="192.553"
                                            y1="28.553"
                                            x2="899.66"
                                            y2="735.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"></stop>
                                            <stop
                                                offset="1"
                                                stopColor="#fff"
                                                stopOpacity="0"
                                            ></stop>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div
                                className="absolute top-0 bottom-0 right-0 hidden w-1/2 pointer-events-none lg:block"
                                aria-hidden="true"
                            >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width="160"
                                    height="678"
                                    viewBox="0 0 160 678"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                        fill="url(#linear3)"
                                        fillOpacity=".1"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="linear3"
                                            x1="192.553"
                                            y1="325.553"
                                            x2="899.66"
                                            y2="1032.66"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#fff"></stop>
                                            <stop
                                                offset="1"
                                                stopColor="#fff"
                                                stopOpacity="0"
                                            ></stop>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <h3 className="text-lg font-bold text-white">
                                Buche den Verzehr für andere!
                            </h3>
                            <p className="max-w-3xl mt-6 text-base text-indigo-50">
                                Moin, hier kannst du den Verzehr für andere
                                Buchen. Dies ist ein Feature für Admins. Bitte
                                vorsichtig damit umgehen!
                            </p>
                        </div>

                        <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
                            {isLoading && <Spinner />}
                            {!isLoading && (
                                <form
                                    className="flex flex-col"
                                    onSubmit={bookDrinkSubmitHandler}
                                >
                                    <UserSelect
                                        handleUserSelection={
                                            handleUserSelection
                                        }
                                    />

                                    <DrinkList
                                        onItemClick={addToCart}
                                        guestBooking={false}
                                        addMoneyOption={addMoneyOption}
                                    />

                                    {showMoneyOption && (
                                        <div className="flex items-start w-full mt-8">
                                            <Input
                                                label="Zusätzlicher Betrag:"
                                                type="number"
                                                id="additionalMoney"
                                                element="input"
                                                className="w-full"
                                                errorText="Über 0 Euro"
                                                onInput={inputHandler}
                                                validators={[VALIDATOR_MIN(0)]}
                                            />
                                            <button
                                                onClick={() => addMoneyToCart()}
                                                className="inline-flex items-center justify-center w-full h-10 px-6 py-3 mt-6 ml-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                            >
                                                Hinzufügen
                                            </button>
                                        </div>
                                    )}

                                    <CartOverview
                                        cartState={cartState}
                                        onItemClick={removeFromCart}
                                        guestBooking={false}
                                        onInput={inputHandler}
                                    />

                                    <div className="flex justify-end mt-2">
                                        <button
                                            disabled={
                                                !(
                                                    selectedUser &&
                                                    formState.isValid &&
                                                    cartState.cart.length > 0
                                                )
                                            }
                                            type="submit"
                                            className="inline-flex items-center justify-center w-full px-6 py-3 mt-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto"
                                        >
                                            Buchen{' '}
                                            {selectedUser &&
                                                `für ${selectedUser.username}`}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookForOther
