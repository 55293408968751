import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const useQuery = () => {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Redirect = () => {
    const query = useQuery()

    const navigate = useNavigate()

    useEffect(
        () =>
            setTimeout(
                () =>
                    query.get('uri')
                        ? navigate(query.get('uri'))
                        : navigate('/'),
                250
            ),
        [navigate, query]
    )
    return <></>
}

export default Redirect
