import React from 'react'

const TextHeader = props => {
    return (
        <div className="flex text-4xl font-semibold mb-8">
            <p>
                {props.text}&nbsp;{props.name}
            </p>
            {props.name && (
                <React.Fragment>
                    <span>&nbsp;</span>
                    <span>&#128075;</span>
                </React.Fragment>
            )}
        </div>
    )
}

export default TextHeader
