import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Spinner from '../../shared/components/UIElements/Spinner'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

const IdentityToken = props => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const { isLoading, sendRequest } = useHttpClient()

    const [identityToken, setIdentityToken] = useState()

    const generateTokenSubmitHandler = async event => {
        event.preventDefault()
        try {
            const resData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/identitytoken/${props.userId}`,
                'POST',
                null,
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success('Identitäts-Token erstellt')
            setIdentityToken(resData.identitytoken)
        } catch (e) {}
    }

    return (
        <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && (
                <div className="py-4 grid grid-cols-3">
                    <p className="block col-span-1 font-semibold">
                        Identitäts-Token generieren
                    </p>
                    <div className="col-span-2">
                        <button
                            onClick={generateTokenSubmitHandler}
                            type="button"
                            className="
                                disabled:opacity-50
                                disabled:cursor-not-allowed
                            w-full
                            flex
                            justify-center
                            py-2
                            px-4
                            border border-transparent
                            rounded-md
                            shadow-sm
                            text-sm
                            font-medium
                            text-white
                            bg-indigo-600
                            hover:bg-indigo-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-indigo-500
                        "
                        >
                            Generieren
                        </button>
                        {identityToken && (
                            <React.Fragment>
                                <p className="mt-6 text-sm font-medium text-gray-900">
                                    Token:&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className="text-lg font-semibold">
                                        {identityToken}
                                    </span>
                                </p>
                                <p className="mt-3 text-gray-500">
                                    Dieser Token ist nur einmal sichtbar. Danach
                                    wird er nicht mehr angezeigt.
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default IdentityToken
