import React from 'react'
import { Outlet } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCog } from '@fortawesome/free-solid-svg-icons'

import PageHeading from '../../shared/components/UIElements/PageHeading'
import SideNavigation from '../../shared/components/UIElements/SideNavigation'
import SideEntry from '../../shared/components/UIElements/SideEntry'

const AccountManagment = () => {
    return (
        <React.Fragment>
            <PageHeading title="Deine Account" />
            <div className="max-w-7xl mx-auto pb-10 lg:py-12 ">
                <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <SideNavigation>
                        <SideEntry name="Übersicht" path="/account">
                            <FontAwesomeIcon icon={faUser} />
                        </SideEntry>
                        {/*                      <SideEntry
                            name="Einstellungen"
                            path="/account/settings"
                        >
                            <FontAwesomeIcon icon={faCog} />
                        </SideEntry> */}
                    </SideNavigation>

                    <section className="col-span-9">
                        <Outlet />
                    </section>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccountManagment
