import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const EmptyState = props => {
    return (
        <div className="bg-white shadow border-gray-200 sm:rounded-lg p-8 py-24 flex flex-col justify-center items-center space-y-4">
            <FontAwesomeIcon
                size="3x"
                icon={faExclamationCircle}
                color="gray"
            />
            {props.title && (
                <h3 className="text-3xl font-medium leading-6 text-gray-900">
                    {props.title}
                </h3>
            )}
            {props.description && (
                <p className="text-md text-gray-500">{props.description}</p>
            )}
        </div>
    )
}

export default EmptyState
