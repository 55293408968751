import React, { useContext, useState } from 'react'
import {
    getDateKey,
    getSessionStorageOrDefault,
    setToStorage,
} from '../util/sessionStorage'

import { AuthContext } from '../context/authContext'
import { useHttpClient } from '../hooks/httpHook'

const WithAdminAuth = WrappedComponent => {
    const WithAdminAuth = () => {
        const auth = useContext(AuthContext)
        const { isLoading, sendRequest } = useHttpClient()

        const [isVerified, setIsVerified] = useState(
            getSessionStorageOrDefault(getDateKey('isv'), false)
        )

        const [password, setPassword] = useState('')

        const activateAdminVerification = async () => {
            try {
                const res = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/checkPassword`,
                    'POST',
                    JSON.stringify({
                        userId: auth.userId,
                        password: password,
                    }),
                    {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.token}`,
                    }
                )
                if (res.authorized && setToStorage(getDateKey('isv'), true)) {
                    setIsVerified(true)
                }
            } catch (e) {}
        }

        if (!isVerified) {
            return (
                <div className="rounded-lg space-y-4 flex-col text-center border shadow-xl w-full py-12 flex justify-center bg-white">
                    <h1 className="font-bold text-xl">
                        Die Seite ist gesperrt!
                    </h1>
                    <div>
                        <input
                            type="password"
                            placeholder="Passwort"
                            className=" 
                        justify-self-center
          
            w-96
            px-3
            py-2
            border border-gray-300
            rounded-md
            shadow-sm
            placeholder-gray-400
            focus:outline-none
            focus:ring-indigo-500
            focus:border-indigo-500   
            sm:text-sm"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <button
                            disabled={isLoading}
                            className="inline-flex items-center justify-center w-40 px-6 py-3 mt-2 font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm sm:w-auto"
                            onClick={activateAdminVerification}
                        >
                            Entsperren
                        </button>
                    </div>
                </div>
            )
        }

        return <WrappedComponent />
    }

    return WithAdminAuth
}

export default WithAdminAuth
