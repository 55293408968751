import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import UserSelect from '../../shared/components/FormElements/UserSelect'
import PageHeading from '../../shared/components/UIElements/PageHeading'
import {
    VALIDATOR_MAXLENGTH,
    VALIDATOR_MINLENGTH,
} from '../../shared/util/validators'

import Input from '../../shared/components/FormElements/Input'
import Spinner from '../../shared/components/UIElements/Spinner'
import { useForm } from '../../shared/hooks/formHook'
import { useHttpClient } from '../../shared/hooks/httpHook'
import { AuthContext } from '../../shared/context/authContext'

const UserPincode = () => {
    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    const [selectedUser, setSelectedUser] = useState()
    const { isLoading, sendRequest } = useHttpClient()

    const [formState, inputHandler, setFormData] = useForm(
        {
            newpincode: {
                value: '',
                isValid: false,
            },
            confirmationpincode: {
                value: '',
                isValid: false,
            },
        },
        false
    )

    const changePincodeSubmitHandler = async event => {
        event.preventDefault()
        if (!selectedUser) return
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/changepincode/${selectedUser.id}`,
                'POST',
                JSON.stringify({
                    newpincode: formState.inputs.newpincode.value,
                    confirmationpincode:
                        formState.inputs.confirmationpincode.value,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.token}`,
                }
            )
            toast.success('Pincode wurde erfolgreich geändert')
            navigate('/redirect?uri=/changepincode')
        } catch (e) {}
    }

    const handleUserSelection = user => {
        setSelectedUser(user)
    }

    return (
        <React.Fragment>
            <PageHeading
                title="Benutzer Pincode"
                buttonText="Zurück"
                onClick={() => navigate('/admin')}
            />
            <div className="pb-10 mx-auto max-w-7xl lg:py-12 ">
                <div className="flex flex-col px-6 py-8 space-y-4 bg-white border-gray-200 divide-y-2 shadow sm:rounded-lg ">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Benutzer Pincode ändern
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Hier kannst du den Pincode von Benutzern ändern.
                        </p>
                    </div>
                    <div className="py-4">
                        <form
                            className="col-span-2 space-y-2"
                            onSubmit={changePincodeSubmitHandler}
                        >
                            <UserSelect
                                label="Benutzer"
                                handleUserSelection={handleUserSelection}
                            />
                            <Input
                                element="input"
                                id="newpincode"
                                type="text"
                                label="Pincode"
                                errorText="Der Pincode muss 4 Zeichen haben."
                                validators={[
                                    VALIDATOR_MINLENGTH(4),
                                    VALIDATOR_MAXLENGTH(4),
                                ]}
                                onInput={inputHandler}
                            />
                            <Input
                                element="input"
                                id="confirmationpincode"
                                type="text"
                                label="Pincode bestätigen"
                                errorText="Der Pincode muss 4 Zeichen haben."
                                validators={[
                                    VALIDATOR_MINLENGTH(4),
                                    VALIDATOR_MAXLENGTH(4),
                                ]}
                                onInput={inputHandler}
                            />
                            <button
                                disabled={!formState.isValid}
                                type="submit"
                                className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Pincode ändern
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserPincode
