import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons'

const PersonalStats = props => {
    return (
        <div>
            <h3 className="text-xl leading-6 font-medium text-gray-900">
                Last 30 days
            </h3>

            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                <div className="relative bg-white pt-5 px-4 pb-0 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                        <div
                            className={`absolute rounded-md p-3 px-5 text-white text-xl ${
                                props.balance >= 0
                                    ? 'bg-green-500 '
                                    : 'bg-red-500 '
                            }`}
                        >
                            <FontAwesomeIcon icon={faEuroSign} />
                        </div>
                        <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                            Guthaben
                        </p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                            {parseFloat(props.balance).toFixed(2)}&#8364;
                        </p>
                        {/*       <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                            <svg
                                className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                            <span className="sr-only"> Increased by </span>0
                        </p> */}
                    </dd>
                </div>
            </dl>
        </div>
    )
}

export default PersonalStats
